@font-face {
  font-family: "IconFont";
  src: url("../assets/icon/iconfont.otf");
}

.tabs-icon::before {
  font-family: "IconFont";
  font-size: var(--font-size-72);
  color: var(--white-color);
  margin-top: -10px;
}

.auth-icon::before {
  font-family: "IconFont";
  font-size: var(--font-size-60);
  color: var(--green-color);
  margin: -20px 15px 0 0;
}

.user-icon::before {
  content: "\004D";
}

.password-icon::before {
  content: "\004C";
}

.beds-icon::before {
  content: "\0045";
  opacity: 0.6;
}
.selected-beds-icon::before {
  content: "\0044";
}
.knowledge-icon::before {
  content: "\0043";
  opacity: 0.6;
}
.selected-knowledge-icon::before {
  content: "\0042";
}
.kitchen-icon::before {
  content: "\0047";
  opacity: 0.6;
}
.selected-kitchen-icon::before {
  content: "\0046";
}
.more-icon::before {
  content: "\0049";
  opacity: 0.6;
}
.selected-more-icon::before {
  content: "\0048";
}
.chat-icon::before {
  content: "\0056";
  opacity: 0.6;
}
.selected-chat-icon::before {
  content: "\0055";
}
.info-icon::before {
  content: "\004B";
  opacity: 0.6;
}
.selected-info-icon::before {
  content: "\004A";
}
