/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/icons.scss";

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.list-header {
  display: grid;
  justify-content: center;
  --color: var(--blue-color);
  padding: 10px 0;
  border: none;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 700;
  font-size: var(--font-size-28);
  color: var(--blue-color);
  background-color: var(--white-color);
  position: sticky;
  top: 0;
  z-index: 99;
}

#webWidget {
  padding-top: var(--ion-safe-area-top);
}

.splash-fullscreen {
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  background-color: var(--pink-color);
}

.logo {
  width: var(--logo-width, 100px);
  margin: calc(var(--logo-width, 100px) / 3);
}

.content {
  --background: var(--pink-color);

  .btn {
    --background: var(--green-color);
    --background-hover: var(--green-color);
    --background-activated: var(--green-color);
    --color: var(--pink-color);
  }

  form {
    display: flex;
    flex-direction: column;

    .button-submit {
      --background: var(--green-color);
      --color: var(--pink-color);
      --border-radius: var(--form-button-border-radius);
      --padding-top: 27px;
      --padding-bottom: 27px;
      --box-shadow: none;
      margin-top: var(--form-button-margin-top);
  
      ion-text {
        font-size: var(--font-size-16);
        font-weight: var(--form-button-text-weight);
        letter-spacing: normal;
        text-transform: none;
      }
    }

    .item-field {
      --background: var(--pink-color);
      --highlight-height: 0;
      --border-color: var(--green-color);
      --border-width: 0px 0px 2px 0px;
      padding-bottom: var(--form-item-padding-bottom);
    }
  }
}

// after migrating from ionic 6 to 7, ion-slides is removed and replaced by the swiper library.
// the following rules ensure that swiper slides look the same by default as ion-slides.
// copied from https://ionicframework.com/docs/angular/slides#additional-ion-slides-styles
swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}
