// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  /** colors **/
  --white-color: white;
  --pink-color: #FBF5EE;
  --black-color: black;
  --green-color: #006432;
  --blue-color: #000090;
  --orange-color: #DF581E;
  --yellow-color: #F0C800;

  --ion-text-color: var(--green-color);
  --ion-color-warning: var(--orange-color);
  --ion-color-warning-contrast: var(--white-color);

  /** fonts **/
  --font-size-72: 72px;
  --font-size-60: 60px;
  --font-size-32: 32px;
  --font-size-28: 28px;
  --font-size-26: 26px;
  --font-size-24: 24px;
  --font-size-22: 22px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-17: 17px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --ion-font-family: 'Rubik', sens-serif;

  /** sizes **/
  --main-header-height: 64px;
  --back-button-header-height: 42px;

  ion-card {
    --color: var(--green-color)
  }

  ion-button {
    --color: var(--green-color)
  }

  --logo-width: 150px;
  --form-item-padding-bottom: 10px;
  --form-button-margin-top: 50px;
  --form-button-border-radius: 3px;
  --form-button-text-weight: 500;
}
